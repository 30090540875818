import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Form from "../containers/LeadRegistration/Form"

const LeadRegistrationPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={"Lead Registration"} description="" noIndex />
      <Banner title="Lead Registration" />
      <Form />
    </Layout>
  )
}

export default LeadRegistrationPage
