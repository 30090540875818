import React from "react"

import * as styles from "./index.module.scss"

const Form = () => {
  return (
    <div className={`max-w-4xl mx-auto mt-8 xl:mt-14 ${styles.leadForm}`}>
      <div className="px-2 mb-8 xl:mb-20 xl:px-0">
        <p>
          Thank you for registering a new B2BNinja Lead! We are excited to
          revolutionize the quoting process of BigCommerce customers. Once
          submitted we will follow up with merchant with next steps!
        </p>
        <br />
        <p>
          If you want to put the merchant in touch with us you can email a warm
          introduction to{" "}
          <a href="mailto:sales@b2bninja.com" className="hoverLink">
            sales@b2bninja.com
          </a>{" "}
          or set up a demo for them at{" "}
          <a href="https://calendly.com/quote-ninja/demo" className="hoverLink">
            https://calendly.com/quote-ninja/demo
          </a>
          .
        </p>
        <br />
        <p>
          They can also download us in the App store for a free 14 day trial. If
          you have any further questions, you can reach as at{" "}
          <a href="mailto:sales@b2bninja.com" className="hoverLink">
            sales@b2bninja.com
          </a>
        </p>
        <br />
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdshny_fLdE8bOAbmj40CeDjzOopGxoE6uQAjn0bkUU2lLo5g/viewform?embedded=true"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          className="mx-auto"
        >
          Loading...
        </iframe>
      </div>
    </div>
  )
}

export default Form
